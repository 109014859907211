import React from "react";
import "./soon.scss";
import Comingsoon from "../../../../media/Launch_Desktop.webp";
import Soon from "../../../../media/Launch_Mobile.webp";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6287860478606&text=Halo%20Gerry,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Asterra%20https://marketing-bsdcity.id/&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const soon = () => {
  return (
    <div id="Asterra" className="container-soon">
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={Comingsoon} alt="maps" />
        <img id="lokasi" className="img-lokasi-mobile" src={Soon} alt="maps" />
      </div>
      <div className="button">
        <div className="container-wa">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default soon;
