import React from "react";
import Banner from "../../component/Banner/Banner";
import BannerRuko from "../../media/banner-ruko.webp";
import BannerRukoMobile from "../../media/banner-ruko-mobile.png";
import Launching from "../../section/CommercialSection/Vanica/Vanika";
import Launchingcom from "../../section/CommercialSection/launching-com/Launching-com";
import Statistik from "../../component/Statistik/Statistik";
import Produkbanner from "../../component/ProdukBanner/Produkbanner";
import ProdukRuko from "../../media/produk-banner-ruko.webp";
import Cardcommercial from "../../section/CommercialSection/CardCommercial/Cardcommercial";
import Bannerpenawaran from "../../component/BannerPenawaran/Bannerpenawaran";
import Bannerpenawaranimage from "../../media/banner-penawaran-resident.webp";
import Surrounding from "../../component/Surrounding/Surrounding";
import Footer from "../../component/Footer/Footer";
import ScrollToHashElement from "../../component/ScrollToHash/ScrollToHash";

const Commercial = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Banner imageA={BannerRuko} imageB={BannerRukoMobile} />
      <Launching />
      <Launchingcom />
      <Statistik />
      <Produkbanner produk={ProdukRuko} text={"Commercial (Ruko)"} />
      <Cardcommercial />
      <Bannerpenawaran
        produk={Bannerpenawaranimage}
        text={"Pilihan Rumah BSD City"}
        subtext={"Start 4 Juta/Bulan Tanpa DP"}
        buttontext={"More Info"}
        link={"/"}
      />
      <Surrounding />
      <Footer />
    </div>
  );
};

export default Commercial;
