import React from "react";
import "./Vanika.scss";

import Soon from "./comingsoon/soon.jsx";

const apartemen = () => {
  return (
    <div id="Newlaunch" className="apartment">
      <div className="container-header1"> New Launching </div>
      <div className="container-header2"> Asterra BSD City</div>

      <div>
        <div className="Judul"></div>
        <Soon />
      </div>
    </div>
  );
};

export default apartemen;
